/////////////////////////////////////////////////////////
// Configurations related to listing.                  //
// Main configuration here is the extended data config //
/////////////////////////////////////////////////////////

// Note: The listingFields come from listingFields asset nowadays by default.
//       To use this built-in configuration, you need to change the overwrite from configHelper.js
//       (E.g. use mergeDefaultTypesAndFieldsForDebugging func)

/**
 * Configuration options for listing fields (custom extended data fields):
 * - key:                           Unique key for the extended data field.
 * - scope (optional):              Scope of the extended data can be either 'public' or 'private'.
 *                                  Default value: 'public'.
 *                                  Note: listing doesn't support 'protected' scope atm.
 * - includeForListingTypes:        An array of listing types, for which the extended
 *   (optional)                     data is relevant and should be added.
 * - schemaType (optional):         Schema for this extended data field.
 *                                  This is relevant when rendering components and querying listings.
 *                                  Possible values: 'enum', 'multi-enum', 'text', 'long', 'boolean'.
 * - enumOptions (optional):        Options shown for 'enum' and 'multi-enum' extended data.
 *                                  These are used to render options for inputs and filters on
 *                                  EditListingPage, ListingPage, and SearchPage.
 * - filterConfig:                  Filter configuration for listings query.
 *    - indexForSearch (optional):    If set as true, it is assumed that the extended data key has
 *                                    search index in place. I.e. the key can be used to filter
 *                                    listing queries (then scope needs to be 'public').
 *                                    Note: Flex CLI can be used to set search index for the key:
 *                                    https://www.sharetribe.com/docs/references/extended-data/#search-schema
 *                                    Read more about filtering listings with public data keys from API Reference:
 *                                    https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
 *                                    Default value: false,
 *   - filterType:                    Sometimes a single schemaType can be rendered with different filter components.
 *                                    For 'enum' schema, filterType can be 'SelectSingleFilter' or 'SelectMultipleFilter'
 *   - label:                         Label for the filter, if the field can be used as query filter
 *   - searchMode (optional):         Search mode for indexed data with multi-enum schema.
 *                                    Possible values: 'has_all' or 'has_any'.
 *   - group:                         SearchPageWithMap has grouped filters. Possible values: 'primary' or 'secondary'.
 * - showConfig:                    Configuration for rendering listing. (How the field should be shown.)
 *   - label:                         Label for the saved data.
 *   - isDetail                       Can be used to hide detail row (of type enum, boolean, or long) from listing page.
 *                                    Default value: true,
 * - saveConfig:                    Configuration for adding and modifying extended data fields.
 *   - label:                         Label for the input field.
 *   - placeholderMessage (optional): Default message for user input.
 *   - isRequired (optional):         Is the field required for providers to fill
 *   - requiredMessage (optional):    Message for those fields, which are mandatory.
 */
export const categoryFields = [
  {
    label: 'Suites for rent',
    key: 'suitesForRent',
    option: 'suitesForRent',
    subCategories: [
      {
        label: 'Suite',
        key: 'suite',
        option: 'suite',
      },
      {
        label: 'Penthouse',
        key: 'penthouse',
        option: 'penthouse',
      },
    ],
  },
  {
    label: 'Villas for rent',
    key: 'villasForRent',
    option: 'villasForRent',
    subCategories: [
      {
        label: 'Villa',
        key: 'villa',
        option: 'villa',
      },
      {
        label: 'Cottage',
        key: 'cottage',
        option: 'cottage',
      },
      {
        label: 'Estates',
        key: 'estates',
        option: 'estates',
      },
      {
        label: 'Private Island',
        key: 'private-island',
        option: 'private-island',
      },
      {
        label: 'Tent',
        key: 'tent',
        option: 'tent',
      },
    ],
  },
  {
    label: 'Off-market',
    key: 'offMarket',
    option: 'offMarket',
    subCategories: [
      {
        label: 'For rent',
        key: 'for-rent',
        option: 'for-rent',
      },
      {
        label: 'For sale',
        key: 'for-sale1',
        option: 'for-sale1',
      },
    ],
  },
  {
    label: 'For Sale',
    key: 'forSale',
    option: 'forSale',
    subCategories: [],
  },
  {
    label: 'Exclusive Use hotels',
    key: 'exclusiveUseHotels',
    option: 'exclusiveUseHotels',
    subCategories: [],
  },
];
export const subCategoryFilter = {
  key: 'subCategory',
  scope: 'public',
  schemaType: 'enum',
  label: 'Sub category',
  enumOptions: categoryFields[0]?.subCategories,

  filterConfig: {
    indexForSearch: true,
    filterType: 'SelectSingleFilter',
    label: 'Sub category',
    group: 'primary',
  },
  showConfig: {
    label: 'Sub category',
    isDetail: true,
  },
  saveConfig: {
    label: 'Sub category',
    placeholderMessage: 'Select an option…',
    isRequired: true,
    requiredMessage: 'You need to select a category.',
  },
};
export const listingTypeFilter = [
  {
    label: 'Exclusive use',
    option: 'exclusive-use',
  },
  {
    label: 'For sale',
    option: 'for-sale',
  },
  {
    label: 'Price on application',
    option: 'poa',
  },
  {
    label: 'Villas For Rent',
    option: 'villas-for-rent',
  },
  {
    label: 'Suites For Rent',
    option: 'suites-for-rent',
  },
];
export const listingFields = [
  // {
  //   key: 'listingType',
  //   scope: 'public',
  //   schemaType: 'enum',
  //   label: 'Listing type',
  //   enumOptions: listingTypeFilter,

  //   filterConfig: {
  //     indexForSearch: true,
  //     filterType: 'SelectSingleFilter',
  //     label: 'Listing type',
  //     group: 'primary',
  //   },
  //   showConfig: {
  //     label: 'Listing type',
  //     isDetail: true,
  //   },
  //   saveConfig: {
  //     label: 'Listing type',
  //     placeholderMessage: 'Select an option…',
  //     isRequired: true,
  //     requiredMessage: 'You need to select a listing type.',
  //   },
  // },
  {
    key: 'category',
    scope: 'public',
    schemaType: 'enum',
    label: 'Category',
    enumOptions: categoryFields,

    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectSingleFilter',
      label: 'Category',
      group: 'primary',
    },
    showConfig: {
      label: 'Category',
      isDetail: true,
    },
    saveConfig: {
      label: 'Category',
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select a category.',
    },
  },
  // {
  //   key: 'roomsInHotel',
  //   scope: 'public',
  //   schemaType: 'enum',
  //   label: 'roomsInHotel',
  //   enumOptions: [
  //     {
  //       option: 2,
  //       label: '2',
  //     },
  //     {
  //       option: 3,
  //       label: '3',
  //     },
  //     {
  //       label: '108',
  //       option: 108,
  //     },
  //   ],

  //   filterConfig: {
  //     indexForSearch: true,
  //     filterType: 'SelectSingleFilter',
  //     label: 'roomsInHotel',
  //     group: 'primary',
  //   },
  //   showConfig: {
  //     label: 'roomsInHotel',
  //     isDetail: true,
  //   },
  //   saveConfig: {
  //     label: 'Category',
  //     placeholderMessage: 'Select an option…',
  //     isRequired: true,
  //     requiredMessage: 'You need to select a category.',
  //   },
  // },

  // {
  //   key: 'pub_sizeM',
  //   scope: 'public',
  //   schemaType: 'CustomFilter',
  //   label: 'Size (m²)',
  //   id: 'sizeM',
  //   min: 0,
  //   max: 2000,
  //   step: 5,
  //   symbol: 'mm',
  //   queryParamNames: ['pub_sizeM'],
  //   filterConfig: {
  //     indexForSearch: true,
  //     filterType: 'CustomFilter',
  //     label: 'Category',
  //     group: 'primary',
  //   },
  //   showConfig: {
  //     label: 'Size (m²)',
  //     isDetail: true,
  //   },
  //   saveConfig: {
  //     label: 'Size (m²)',
  //     placeholderMessage: 'Select an option…',
  //     isRequired: true,
  //     requiredMessage: 'You need to select a category.',
  //   },
  // },
  {
    key: 'pub_sizeSqft',
    scope: 'public',
    schemaType: 'CustomFilter',
    label: 'Size (sqft)',
    id: 'sizeSqft',
    queryParamNames: ['pub_sizeSqft'],
    min: 0,
    max: 30000,
    step: 5,
    symbol: 'mm',

    filterConfig: {
      indexForSearch: true,
      filterType: 'CustomFilter',
      label: 'Category',
      group: 'primary',
    },
    showConfig: {
      label: 'Size (sqft)',
      isDetail: true,
    },
    saveConfig: {
      label: 'Size (sqft)',
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select a category.',
    },
  },
  {
    key: 'pub_roomsInHotel',
    scope: 'public',
    schemaType: 'CustomFilter',
    label: 'Rooms (in Hotel)',
    id: 'roomsInHotel',
    queryParamNames: ['pub_roomsInHotel'],
    min: 0,
    max: 4000,
    step: 5,
    symbol: '',

    filterConfig: {
      indexForSearch: true,
      filterType: 'CustomFilter',
      label: 'Category',
      group: 'primary',
    },
    showConfig: {
      label: 'Rooms (in Hotel)',
      isDetail: true,
    },
    saveConfig: {
      label: 'Rooms (in Hotel)',
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select a category.',
    },
  },
  {
    key: 'pub_numberOfBathrooms',
    scope: 'public',
    schemaType: 'CustomFilter',
    label: 'Number of Bathrooms',
    id: 'numberOfBathrooms',
    queryParamNames: ['pub_numberOfBathrooms'],
    min: 0,
    max: 15,
    step: 1,
    symbol: '',

    filterConfig: {
      indexForSearch: true,
      filterType: 'CustomFilter',
      label: 'Number of Bathrooms',
      group: 'primary',
    },
    showConfig: {
      label: 'Number of Bathrooms',
      isDetail: true,
    },
    saveConfig: {
      label: 'Number of Bathrooms',
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select a category.',
    },
  },
  {
    key: 'pub_numberOfBedrooms',
    scope: 'public',
    schemaType: 'CustomFilter',
    label: 'Number of Bedrooms',
    id: 'numberOfBedrooms',
    queryParamNames: ['pub_numberOfBedrooms'],
    min: 0,
    max: 15,
    step: 1,
    symbol: '',

    filterConfig: {
      indexForSearch: true,
      filterType: 'CustomFilter',
      label: 'Number of Bedrooms',
      group: 'primary',
    },
    showConfig: {
      label: 'Number of Bedrooms',
      isDetail: true,
    },
    saveConfig: {
      label: 'Number of Bedrooms',
      placeholderMessage: 'Select an option…',
      isRequired: true,
      requiredMessage: 'You need to select a category.',
    },
  },
  // {
  //   key: 'category',
  //   scope: 'public',
  //   schemaType: 'enum',
  //   enumOptions: [
  //     { option: 'city-bikes', label: 'City bikes' },
  //     { option: 'electric-bikes', label: 'Electric bikes' },
  //     { option: 'mountain-bikes', label: 'Mountain bikes' },
  //     { option: 'childrens-bikes', label: "Children's bikes" },
  //   ],
  //   filterConfig: {
  //     indexForSearch: true,
  //     filterType: 'SelectMultipleFilter',
  //     label: 'Category',
  //     group: 'primary',
  //   },
  //   showConfig: {
  //     label: 'Category',
  //     isDetail: true,
  //   },
  //   saveConfig: {
  //     label: 'Category',
  //     placeholderMessage: 'Select an option…',
  //     isRequired: true,
  //     requiredMessage: 'You need to select a category.',
  //   },
  // },

  // {
  //   key: 'tire',
  //   scope: 'public',
  //   schemaType: 'enum',
  //   enumOptions: [
  //     { option: '29', label: '29' },
  //     { option: '28', label: '28' },
  //     { option: '27', label: '27' },
  //     { option: '26', label: '26' },
  //     { option: '24', label: '24' },
  //     { option: '20', label: '20' },
  //     { option: '18', label: '18' },
  //   ],
  //   filterConfig: {
  //     indexForSearch: true,
  //     label: 'Tire size',
  //     group: 'secondary',
  //   },
  //   showConfig: {
  //     label: 'Tire size',
  //     isDetail: true,
  //   },
  //   saveConfig: {
  //     label: 'Tire size',
  //     placeholderMessage: 'Select an option…',
  //     isRequired: true,
  //     requiredMessage: 'You need to select a tire size.',
  //   },
  // },
  // {
  //   key: 'brand',
  //   scope: 'public',
  //   schemaType: 'enum',
  //   enumOptions: [
  //     { option: 'cube', label: 'Cube' },
  //     { option: 'diamant', label: 'Diamant' },
  //     { option: 'ghost', label: 'GHOST' },
  //     { option: 'giant', label: 'Giant' },
  //     { option: 'kalkhoff', label: 'Kalkhoff' },
  //     { option: 'kona', label: 'Kona' },
  //     { option: 'otler', label: 'Otler' },
  //     { option: 'vermont', label: 'Vermont' },
  //   ],
  //   filterConfig: {
  //     indexForSearch: true,
  //     label: 'Brand',
  //     group: 'secondary',
  //   },
  //   showConfig: {
  //     label: 'Brand',
  //     isDetail: true,
  //   },
  //   saveConfig: {
  //     label: 'Brand',
  //     placeholderMessage: 'Select an option…',
  //     isRequired: true,
  //     requiredMessage: 'You need to select a brand.',
  //   },
  // },
  // {
  //   key: 'accessories',
  //   scope: 'public',
  //   schemaType: 'multi-enum',
  //   enumOptions: [
  //     { option: 'bell', label: 'Bell' },
  //     { option: 'lights', label: 'Lights' },
  //     { option: 'lock', label: 'Lock' },
  //     { option: 'mudguard', label: 'Mudguard' },
  //   ],
  //   filterConfig: {
  //     indexForSearch: true,
  //     label: 'Accessories',
  //     searchMode: 'has_all',
  //     group: 'secondary',
  //   },
  //   showConfig: {
  //     label: 'Accessories',
  //   },
  //   saveConfig: {
  //     label: 'Accessories',
  //     placeholderMessage: 'Select an option…',
  //     isRequired: false,
  //   },
  // },

  // // An example of how to use transaction type specific custom fields and private data.
  // {
  //   key: 'note',
  //   scope: 'public',
  //   includeForListingTypes: ['product-selling'],
  //   schemaType: 'text',
  //   showConfig: {
  //     label: 'Extra notes',
  //   },
  //   saveConfig: {
  //     label: 'Extra notes',
  //     placeholderMessage: 'Some public extra note about this bike...',
  //   },
  // },
  // {
  //   key: 'privatenote',
  //   scope: 'private',
  //   includeForListingTypes: ['daily-booking'],
  //   schemaType: 'text',
  //   saveConfig: {
  //     label: 'Private notes',
  //     placeholderMessage: 'Some private note about this bike...',
  //   },
  // },
];

///////////////////////////////////////////////////////////////////////
// Configurations related to listing types and transaction processes //
///////////////////////////////////////////////////////////////////////

// A presets of supported listing configurations
//
// Note 1: The listingTypes come from listingTypes asset nowadays by default.
//         To use this built-in configuration, you need to change the overwrite from configHelper.js
//         (E.g. use mergeDefaultTypesAndFieldsForDebugging func)
// Note 2: transaction type is part of listing type. It defines what transaction process and units
//         are used when transaction is created against a specific listing.

/**
 * Configuration options for listing experience:
 * - listingType:         Unique string. This will be saved to listing's public data on
 *                        EditListingWizard.
 * - label                Label for the listing type. Used as microcopy for options to select
 *                        listing type in EditListingWizard.
 * - transactionType      Set of configurations how this listing type will behave when transaction is
 *                        created.
 *   - process              Transaction process.
 *                          The process must match one of the processes that this client app can handle
 *                          (check src/util/transactions/transaction.js) and the process must also exists in correct
 *                          marketplace environment.
 *   - alias                Valid alias for the aforementioned process. This will be saved to listing's
 *                          public data as transctionProcessAlias and transaction is initiated with this.
 *   - unitType             Unit type is mainly used as pricing unit. This will be saved to
 *                          transaction's protected data.
 *                          Recommendation: don't use same unit types in completely different processes
 *                          ('item' sold should not be priced the same as 'item' booked).
 * - stockType            This is relevant only to listings using default-purchase process.
 *                        If set to 'oneItem', stock management is not showed and the listing is
 *                        considered unique (stock = 1).
 *                        Possible values: 'oneItem' and 'multipleItems'.
 *                        Default: 'multipleItems'.
 * - defaultListingFields These are tied to transaction processes. Different processes have different flags.
 *                        E.g. default-inquiry can toggle price and location to true/false value to indicate,
 *                        whether price (or location) tab should be shown. If defaultListingFields.price is not
 *                        explicitly set to _false_, price will be shown.
 *                        If the location or pickup is not used, listing won't be returned with location search.
 *                        Use keyword search as main search type if location is not enforced.
 *                        The payoutDetails flag allows provider to bypass setting of payout details.
 *                        Note: customers can't order listings, if provider has not set payout details! Monitor
 *                        providers who have not set payout details and contact them to ensure that they add the details.
 */

export const listingTypes = [
  {
    listingType: 'daily-booking',
    label: 'Daily booking',
    transactionType: {
      process: 'default-booking',
      alias: 'default-booking/release-1',
      unitType: 'day',
    },
    defaultListingFields: {
      location: true,
      payoutDetails: true,
    },
  },
  // // Here are some examples for other listingTypes
  // // TODO: SearchPage does not work well if both booking and product selling are used at the same time
  // {
  //   listingType: 'nightly-booking',
  //   label: 'Nightly booking',
  //   transactionType: {
  //     process: 'default-booking',
  //     alias: 'default-booking/release-1',
  //     unitType: 'night',
  //   },
  // },
  // {
  //   listingType: 'hourly-booking',
  //   label: 'Hourly booking',
  //   transactionType: {
  //     process: 'default-booking',
  //     alias: 'default-booking/release-1',
  //     unitType: 'hour',
  //   },
  // },
  // {
  //   listingType: 'product-selling',
  //   label: 'Sell bicycles',
  //   transactionType: {
  //     process: 'default-purchase',
  //     alias: 'default-purchase/release-1',
  //     unitType: 'item',
  //   },
  //   stockType: 'multipleItems',
  //   defaultListingFields: {
  //     shipping: true,
  //     pickup: true,
  //     payoutDetails: true,
  //   },
  // },
  // {
  //   listingType: 'inquiry',
  //   label: 'Inquiry',
  //   transactionType: {
  //     process: 'default-inquiry',
  //     alias: 'default-inquiry/release-1',
  //     unitType: 'inquiry',
  //   },
  //   defaultListingFields: {
  //     price: false,
  //     location: true,
  //   },
  // },
];

// SearchPage can enforce listing query to only those listings with valid listingType
// However, it only works if you have set 'enum' type search schema for the public data fields
//   - listingType
//
//  Similar setup could be expanded to 2 other extended data fields:
//   - transactionProcessAlias
//   - unitType
//
// Read More:
// https://www.sharetribe.com/docs/how-to/manage-search-schemas-with-flex-cli/#adding-listing-search-schemas
export const enforceValidListingType = false;
