import LayoutComposer from './LayoutComposer.js';
import LayoutSingleColumn from './LayoutSingleColumn/LayoutSingleColumn';
import LayoutSideNavigation from './LayoutSideNavigation/LayoutSideNavigation';

import LayoutWrapperMain from './LayoutWrapperMain/LayoutWrapperMain.js';
import LayoutWrapperTopbar from './LayoutWrapperTopbar/LayoutWrapperTopbar.js';
import LayoutWrapperFooter from './LayoutWrapperFooter/LayoutWrapperFooter.js';
export { LayoutSingleColumn, LayoutSideNavigation ,LayoutWrapperFooter,LayoutWrapperTopbar,LayoutWrapperMain};
// export { LayoutSingleColumn, LayoutSideNavigation };
export default LayoutComposer;
