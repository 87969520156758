import React from 'react';
import { Form as FinalForm } from 'react-final-form';
import {
  FieldLocationAutocompleteInput,
  FieldTextInput,
  Form,
  PrimaryButton,
} from '../../components';
import LocationSearch from './LocationSearch';
import css from './SearchBar.module.css';
import { FormattedMessage, useIntl } from 'react-intl';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { LiaSearchSolid } from 'react-icons/lia';
const identity = v => v;

function SearchForm({ onSubmit }) {
  return (
    <FinalForm
      initialValues={{ type: 'location' }}
      onSubmit={onSubmit}
      render={formRenderProps => {
        const { handleSubmit, values, pristine } = formRenderProps;
        const routeConfiguration = useRouteConfiguration();
        const intl = useIntl();
        const searchDisabled =
          pristine || !values.location || !values.startDate || !values.endDate || dateError;

        return (
          <Form onSubmit={handleSubmit} className={css.root}>
            <div className={css.locationContainer}>
              <LiaSearchSolid className={css.desktopicon} />
              <FieldTextInput
                className={css.keywordField}
                inputRootClass={css.keywordInputRoot}
                id="keyword"
                name="keyword"
                type="text"
                placeholder={intl.formatMessage({ id: 'SearchBar.SearchBar.keywordPlaceholder' })}
                isLandingPage={true}
              />

              <LocationSearch
                className={css.locationField}
                id="location"
                name="location"
                // className={css.dateField}
                values={values}
                routeConfiguration={routeConfiguration}
              />
              <LiaSearchSolid className={css.mobileIcon} onClick={handleSubmit} />
              <PrimaryButton
                type="submit"
                className={css.searchButton}
                // disabled={searchDisabled}
              >
                <FormattedMessage id="SearchBar.SearchBar.searchButtonLabel" />
              </PrimaryButton>
            </div>
          </Form>
        );
      }}
    />
  );
}

export default SearchForm;
