import { parse } from '../../util/urlHelpers';
import { blogLoader, DEFAULT_PAGE_SIZE } from '../../util/blogLoader';

// ================ Action types ================ //
const BLOG_POSTS_FETCH_REQUEST = 'app/BlogPostsPage/BLOG_POSTS_FETCH_REQUEST';
const BLOG_POSTS_FETCH_SUCCESS = 'app/BlogPostsPage/BLOG_POSTS_FETCH_SUCCESS';
const BLOG_POSTS_FETCH_ERROR = 'app/BlogPostsPage/BLOG_POSTS_FETCH_ERROR';

const BLOG_PAGINATION_FETCH_REQUEST = 'app/BlogPostsPage/BLOG_PAGINATION_FETCH_REQUEST';
const BLOG_PAGINATION_FETCH_SUCCESS = 'app/BlogPostsPage/BLOG_PAGINATION_FETCH_SUCCESS';
const BLOG_PAGINATION_FETCH_ERROR = 'app/BlogPostsPage/BLOG_PAGINATION_FETCH_ERROR';
const BLOG_CHANGE_CURRENT_PAGE = 'app/BlogPostsPage/BLOG_CHANGE_CURRENT_PAGE';

// ================ Reducer ================ //

// _start=10&_limit=10

const initialState = {
  posts: [],
  postsLoading: false,
  postsLoadingError: null,
  totalPostsCount: null,
  totalPostsCountLoading: false,
  totalPostsCountError: null,
  currentPage: 1,
  pageSize: DEFAULT_PAGE_SIZE,
};

const blogPostsPageLoader = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case BLOG_POSTS_FETCH_REQUEST:
      return {
        ...state,
        postsLoading: true,
        totalPostsCountError: null,
        postsLoadingError: null,
      };
    case BLOG_POSTS_FETCH_SUCCESS:
      return {
        ...state,
        posts: payload,
        postsLoading: false,
      };
    case BLOG_POSTS_FETCH_ERROR:
      return {
        ...state,
        postsLoading: false,
        postsLoadingError: payload,
      };
    case BLOG_PAGINATION_FETCH_REQUEST:
      return {
        ...state,
        totalPostsCountLoading: true,
      };
    case BLOG_PAGINATION_FETCH_SUCCESS:
      return {
        ...state,
        totalPostsCountLoading: false,
        totalPostsCount: payload,
      };
    case BLOG_PAGINATION_FETCH_REQUEST:
      return {
        ...state,
        totalPostsCountLoading: false,
        totalPostsCountError: payload,
      };
    case BLOG_CHANGE_CURRENT_PAGE:
      return {
        ...state,
        currentPage: payload,
      };
    default:
      return state;
  }
};

const blogPostsFetchRequest = () => ({
  type: BLOG_POSTS_FETCH_REQUEST,
  postsLoading: true,
});
const blogPostsFetchSuccess = data => ({
  type: BLOG_POSTS_FETCH_SUCCESS,
  payload: data,
});
const blogPostsFetchError = error => ({
  type: BLOG_POSTS_FETCH_ERROR,
  postsLoadingError: error,
});

const totalPostsCountSuccess = data => ({
  type: BLOG_PAGINATION_FETCH_SUCCESS,
  payload: data,
});
const totalPostsCountError = error => ({
  type: BLOG_PAGINATION_FETCH_ERROR,
  payload: error,
});
const totalPostsCountRequest = () => ({
  type: BLOG_PAGINATION_FETCH_REQUEST,
});
const blogPostsChangePage = page => ({
  type: BLOG_CHANGE_CURRENT_PAGE,
  payload: page,
});

export default blogPostsPageLoader;

const fetchPosts = page => (dispatch, getState, sdk) => {
  // console.log("fetchPosts hited>>>>>>>>>>>>>>>>>>>>>>>>>>>")
  dispatch(blogPostsFetchRequest());
  const start = page > 1 ? (page - 1) * DEFAULT_PAGE_SIZE : 0;
  //fetch posts sort by latest
  return blogLoader.get(`/posts?_sort=createdAt:DESC&_start=${start}&_limit=${DEFAULT_PAGE_SIZE}`);
  // return blogLoader.get(`/posts?_start=${start}&_limit=${DEFAULT_PAGE_SIZE}`);
};

const fetchPostsCount = () => (dispatch, getState, sdk) => {
  console.log("fetchPostsCount HITTEDDD..................")
  dispatch(totalPostsCountRequest());
  return blogLoader.get('/posts/count');
};

export const fetchNextPage = page => async (dispatch, getState, sdk) => {
  dispatch(blogPostsFetchRequest());
  const start = page > 1 ? (page - 1) * DEFAULT_PAGE_SIZE : 0;
  const limit = DEFAULT_PAGE_SIZE;
  try {
    // const response = await blogLoader.get(`/posts?_start=${start}&_limit=${limit}`);
    const response = await blogLoader.get(
      `/posts?_sort=createdAt:DESC&_start=${start}&_limit=${limit}`
    );
    dispatch(blogPostsFetchSuccess(response.data));
    dispatch(blogPostsChangePage(page));
  } catch (e) {
    dispatch(blogPostsFetchError(JSON.stringify(e)));
  }
};

export const loadData = (params, search) => dispatch => {
  console.log("load data in blogpostpage.duck ")
  const { page } = parse(search);
  return Promise.all([dispatch(fetchPosts(page)), dispatch(fetchPostsCount())])
    .then(responses => {
      console.log("responses here",responses)
      dispatch(blogPostsFetchSuccess(responses[0].data));
      if (page) dispatch(blogPostsChangePage(page));
      dispatch(totalPostsCountSuccess(responses[1].data));
    })
    .catch(errors => {
      console.log("errorrr>>>",errors)
      dispatch(blogPostsFetchError(JSON.stringify(errors[0])));
      dispatch(totalPostsCountError(JSON.stringify(errors[1])));
    });
};
