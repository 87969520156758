import axios from 'axios';
import { blogLoader, DEFAULT_PAGE_SIZE } from '../../util/blogLoader';

// ================ Action types ================ //
const CATEGORY_POSTS_PAGE_FETCH_REQUEST = 'app/CategoryPage/CATEGORY_POSTS_PAGE_FETCH_REQUEST';
const CATEGORY_POSTS_PAGE_FETCH_SUCCESS = 'app/CategoryPage/CATEGORY_POSTS_PAGE_FETCH_SUCCESS';
const CATEGORY_POSTS_PAGE_FETCH_ERROR = 'app/CategoryPage/CATEGORY_POSTS_PAGE_FETCH_ERROR';

// ================ Reducer ================ //

const initialState = {
  posts: [],
  postsLoading: false,
  postsLoadingError: null,
  Category: null,
  created_at: null,
  updated_at: null,
  published_at: null,
};

const categoryPagePostsPageLoader = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case CATEGORY_POSTS_PAGE_FETCH_REQUEST:
      return {
        ...state,
        ...payload,
      };
    case CATEGORY_POSTS_PAGE_FETCH_SUCCESS:
      
      return {
        ...state,
        ...payload,
      };
    case CATEGORY_POSTS_PAGE_FETCH_ERROR:
      return {
        ...state,
        postsLoadingError: payload,
      };

    default:
      return state;
  }
};

const categoryPagePostsFetchRequest = () => ({
  type: CATEGORY_POSTS_PAGE_FETCH_REQUEST,
  postsLoading: true,
});
const categoryPagePostsFetchSuccess = data => (
  {
  type: CATEGORY_POSTS_PAGE_FETCH_SUCCESS,
  payload: data,
}
);
const categoryPagePostsFetchError = error => ({
  type: CATEGORY_POSTS_PAGE_FETCH_ERROR,
  postsLoadingError: error,
});

export default categoryPagePostsPageLoader;

const fetchPostsByCategoryId = id => (dispatch, getState, sdk) => {
  const limit = DEFAULT_PAGE_SIZE;
  dispatch(categoryPagePostsFetchRequest());
  return blogLoader.get(`/categories/${id}?_limit=${limit}`);
};

export const loadData = (params, search) => dispatch => {
  
  const id = params.id;
  return Promise.all([dispatch(fetchPostsByCategoryId(id))])
    .then(responses => {
      console.log("RESPONSE",responses)
      const responseData = responses[0].data;

      dispatch(categoryPagePostsFetchSuccess(responseData));
    })
    .catch(errors => {
      dispatch(categoryPagePostsFetchError(JSON.stringify(errors[0])));
    });
};
